@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url('./src/Poppins-Regular.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: url('./src/Poppins-Italic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 250;
  src: url('./src/Poppins-Thin.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 250;
  src: url('./src/Poppins-ThinItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 275;
  src: url('./src/Poppins-ExtraLight.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 275;
  src: url('./src/Poppins-ExtraLightItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url('./src/Poppins-Light.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: url('./src/Poppins-LightItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url('./src/Poppins-Medium.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: url('./src/Poppins-MediumItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url('./src/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: url('./src/Poppins-SemiBoldItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url('./src/Poppins-Bold.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: url('./src/Poppins-BoldItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url('./src/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: url('./src/Poppins-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: url('./src/Poppins-Black.ttf');
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: url('./src/Poppins-BlackItalic.ttf');
}
