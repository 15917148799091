.questionaire {
  position: relative;
  width: 90%;
  height: auto;
  background-color: #ffffff;
  margin: auto;
}

.questionaire .navbar {
  display: flex;
  flex-direction: column;
  width: 1440px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.questionaire .frame {
  display: flex;
  width: 1250px;
  align-items: center;
  gap: 213px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .img {
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-wrapper {
  display: flex;
  flex-direction: column;
  width: 883px;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.questionaire .div-wrapper {
  display: flex;
  width: 877px;
  align-items: center;
  justify-content: flex-end;
  gap: 203px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -14px;
}

.questionaire .div {
  display: inline-flex;
  align-items: flex-start;
  gap: 31px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-2 {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
}

.questionaire .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #1f1f28;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.questionaire .frame-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  padding: 10px;
  position: absolute;
  top: 160px;
  left: 224px;
}

.questionaire .group-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .group {
  width: 145px;
  position: relative;
  height: 40px;
}

.questionaire .group-2 {
  width: 147px;
  position: relative;
  height: 40px;
}

.questionaire .text-wrapper-2 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.questionaire .line {
  position: relative;
  width: 973px;
  height: 1px;
  object-fit: cover;
}

.questionaire .frame-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .group-3 {
  position: relative;
  width: 207px;
  height: 40px;
}

.questionaire .group-4 {
  position: relative;
  width: 209px;
  height: 40px;
}

.questionaire .text-wrapper-3 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.questionaire .frame-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-6 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-7 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-8 {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .frame-9 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-300);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.questionaire .frame-10 {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.questionaire .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.questionaire .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.questionaire .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-300);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}

.questionaire .text-wrapper-8 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}

.questionaire .frame-11 {
  display: inline-flex;
  align-items: center;
  gap: 258px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .vuesax-outline-arrow {
  position: relative;
  width: 24px;
  height: 24px;
}

.questionaire .frame-12 {
  display: inline-flex;
  align-items: center;
  gap: 175px;
  position: relative;
  flex: 0 0 auto;
}

.questionaire .save-and-continue-wrapper {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.questionaire .save-and-continue {
  position: relative;
  width: fit-content;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.questionaire .frame-13 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  position: absolute;
  top: 130px;
  left: 357px;
}

.questionaire .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.questionaire .frame-14 {
  position: relative;
  width: 730px;
  height: 6px;
  margin-bottom: -3px;
  margin-left: -1px;
  margin-right: -3px;
  object-fit: cover;
}

.text-area {
  display: flex;
  width: 478px;
  height: 240px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--foundation-blue-secondary-text-50, #eaeaea);
  background-color: red;
}

.stepThree {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 10px;
  position: relative;
}

.stepThree .group-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .group {
  width: 145px;
  position: relative;
  height: 40px;
}

.stepThree .div-wrapper {
  width: 147px;
  position: relative;
  height: 40px;
}

.stepThree .text-wrapper {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepThree .line {
  position: relative;
  width: 973px;
  height: 1px;
  object-fit: cover;
}

.stepThree .stepThree-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .group-2 {
  width: 189px;
  position: relative;
  height: 40px;
}

.stepThree .group-3 {
  width: 191px;
  position: relative;
  height: 40px;
}

.stepThree .text-wrapper-2 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepThree .please-provide-full {
  position: relative;
  width: 977px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-300);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepThree .stepThree-wrapper-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .stepThree-wrapper-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-6 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .text-wrapper-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-300);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}

.stepThree .stepThree-wrapper-4 {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-wrapper-2 {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepThree .text-wrapper-4 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}

.stepThree .div-7 {
  display: inline-flex;
  align-items: center;
  gap: 322px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .vuesax-outline-arrow {
  position: relative;
  width: 24px;
  height: 24px;
}

.stepThree .stepThree-wrapper-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-wrapper-3 {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationyellowprimary-500);
}

.stepThree .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepThree .group-4 {
  width: 300px;
  position: relative;
  height: 40px;
}

.stepThree .group-5 {
  width: 302px;
  position: relative;
  height: 40px;
}

.stepThree .div-8 {
  display: inline-flex;
  align-items: center;
  gap: 323px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .stepThree-wrapper-6 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 113px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-9 {
  display: inline-flex;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  flex: 0 0 auto;
}

.stepThree .div-wrapper-4 {
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationbluesecondarytext-500);
  border-radius: 4px;
}

.stepThree .text-wrapper-6 {
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  line-height: 32px;
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  text-align: center;
  letter-spacing: 0;
  white-space: nowrap;
}

.stepThree .save-and-continue-wrapper {
  display: flex;
  width: 356px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFour {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  padding: 10px;
  position: relative;
}

.stepFour .group-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .group {
  width: 145px;
  position: relative;
  height: 40px;
}

.stepFour .div-wrapper {
  width: 147px;
  position: relative;
  height: 40px;
}

.stepFour .text-wrapper {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFour .line {
  position: relative;
  width: 973px;
  height: 1px;
  object-fit: cover;
}

.stepFour .stepFour-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .div {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .div-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .group-2 {
  position: relative;
  width: 211px;
  height: 40px;
}

.stepFour .group-3 {
  position: relative;
  width: 213px;
  height: 40px;
}

.stepFour .text-wrapper-2 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFour .please-note-that {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
}

.stepFour .span {
  color: #9d9d9d;
}

.stepFour .text-wrapper-3 {
  color: #ec1c24;
}

.stepFour .div-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .stepFour-wrapper-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .stepFour-wrapper-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .div-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 46px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .div-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .has-the-board-of {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.stepFour .text-wrapper-4 {
  color: #707070;
}

.stepFour .stepFour-wrapper-4 {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .stepFour-wrapper-5 {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFour .div-6 {
  display: inline-flex;
  align-items: center;
  gap: 350px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -2px;
  margin-bottom: -2px;
}

.stepFour .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFour .vuesax-outline-arrow {
  position: relative;
  width: 24px;
  height: 24px;
}

.stepFour .has-the-institution {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepFour .div-7 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .stepFour-wrapper-6 {
  display: flex;
  width: 245px;
  align-items: flex-start;
  gap: 15px;
  position: relative;
}

.stepFour .stepFour-wrapper-7 {
  display: flex;
  width: 245px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFour .div-wrapper-2 {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -2px;
  margin-bottom: -2px;
}

.stepFour .p {
  position: relative;
  width: 509px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepFour .div-8 {
  display: inline-flex;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  flex: 0 0 auto;
}

.stepFour .div-wrapper-3 {
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationbluesecondarytext-500);
  border-radius: 4px;
}

.stepFour .text-wrapper-6 {
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  line-height: 32px;
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  text-align: center;
  letter-spacing: 0;
  white-space: nowrap;
}

.stepFour .save-and-continue-wrapper {
  display: flex;
  width: 356px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFour .save-and-continue {
  position: relative;
  width: fit-content;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFive {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  padding: 10px;
  position: relative;
}

.stepFive .group-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .group {
  width: 145px;
  position: relative;
  height: 40px;
}

.stepFive .div-wrapper {
  width: 147px;
  position: relative;
  height: 40px;
}

.stepFive .text-wrapper {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFive .line {
  position: relative;
  width: 973px;
  height: 1px;
  object-fit: cover;
}

.stepFive .div {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .group-2 {
  position: relative;
  width: 351px;
  height: 40px;
}

.stepFive .group-3 {
  position: relative;
  width: 353px;
  height: 40px;
}

.stepFive .text-wrapper-2 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFive .please-note-that {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
}

.stepFive .span {
  color: #9d9d9d;
}

.stepFive .text-wrapper-3 {
  color: #ec1c24;
}

.stepFive .stepFive-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .stepFive-wrapper-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .stepFive-wrapper-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 46px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-5 {
  display: flex;
  flex-direction: column;
  width: 509px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .p {
  position: relative;
  width: 509px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepFive .text-wrapper-4 {
  color: #707070;
}

.stepFive .stepFive-wrapper-4 {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .stepFive-wrapper-5 {
  display: flex;
  width: 478px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFive .div-6 {
  display: inline-flex;
  align-items: center;
  gap: 350px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -2px;
  margin-bottom: -2px;
}

.stepFive .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.stepFive .vuesax-outline-arrow {
  position: relative;
  width: 24px;
  height: 24px;
}

.stepFive .div-7 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-8 {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 68px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .how-often-are-the {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepFive .stepFive-wrapper-6 {
  display: flex;
  width: 360px;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .stepFive-wrapper-7 {
  display: flex;
  width: 360px;
  height: 56px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFive .div-9 {
  display: inline-flex;
  align-items: center;
  gap: 208px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -2px;
  margin-bottom: -2px;
}

.stepFive .div-wrapper-2 {
  gap: 4px;
  display: inline-flex;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .who-approves-this-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 71px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .who-approves-this {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.stepFive .div-wrapper-3 {
  gap: 4px;
  margin-top: -2px;
  margin-bottom: -2px;
  display: inline-flex;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-10 {
  display: inline-flex;
  align-items: flex-end;
  gap: 68px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-11 {
  display: flex;
  flex-direction: column;
  width: 361px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
}

.stepFive .div-12 {
  position: relative;
  width: 360px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.stepFive .div-13 {
  gap: 232px;
  margin-top: -2px;
  margin-bottom: -2px;
  display: inline-flex;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-14 {
  display: inline-flex;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  flex: 0 0 auto;
}

.stepFive .div-wrapper-4 {
  display: flex;
  flex-direction: column;
  width: 97px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationbluesecondarytext-500);
  border-radius: 4px;
}

.stepFive .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.stepFive .save-and-continue-wrapper {
  display: flex;
  width: 356px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.stepFive .save-and-continue {
  position: relative;
  width: fit-content;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.DOCUMENT-UPLOAD {
  position: relative;
  width: 1440px;
  height: 1668px;
  background-color: #ffffff;
}

.DOCUMENT-UPLOAD .navbar {
  display: flex;
  flex-direction: column;
  width: 1440px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.DOCUMENT-UPLOAD .frame {
  display: flex;
  width: 1250px;
  align-items: center;
  gap: 213px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .img {
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-wrapper {
  flex-direction: column;
  width: 883px;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  padding: 10px;
  position: relative;
}

.DOCUMENT-UPLOAD .div-wrapper {
  display: flex;
  width: 877px;
  align-items: center;
  justify-content: flex-end;
  gap: 203px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -14px;
}

.DOCUMENT-UPLOAD .div {
  display: inline-flex;
  align-items: flex-start;
  gap: 31px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-2 {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
}

.DOCUMENT-UPLOAD .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #1f1f28;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.DOCUMENT-UPLOAD .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 120px;
  left: 203px;
}

.DOCUMENT-UPLOAD .vuesax-outline-arrow {
  position: relative;
  width: 18px;
  height: 18px;
}

.DOCUMENT-UPLOAD .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundationbluesecondarytext-300);
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  position: absolute;
  top: 164px;
  left: 203px;
}

.DOCUMENT-UPLOAD .frame-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-6 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .group {
  width: 289px;
  position: relative;
  height: 40px;
}

.DOCUMENT-UPLOAD .group-2 {
  width: 291px;
  position: relative;
  height: 40px;
}

.DOCUMENT-UPLOAD .text-wrapper-3 {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 32px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.DOCUMENT-UPLOAD .frame-7 {
  display: inline-flex;
  align-items: center;
  gap: 46px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .p {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #a3a4a5;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-8 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-300);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.DOCUMENT-UPLOAD .frame-9 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .text-wrapper-5 {
  position: relative;
  width: 676px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-500);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .text-wrapper-6 {
  position: relative;
  width: 247px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-500);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .text-wrapper-7 {
  position: relative;
  width: 290px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-500);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .line {
  position: relative;
  width: 1030px;
  height: 1px;
  object-fit: cover;
}

.DOCUMENT-UPLOAD .frame-10 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 51px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-11 {
  display: flex;
  flex-direction: column;
  width: 1031px;
  height: 285px;
  align-items: flex-start;
  gap: 51px;
  padding: 0px 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-50);
  border-radius: 4px;
  border: 2px dashed;
  border-color: var(--foundationyellowprimary-500);
}

.DOCUMENT-UPLOAD .frame-12 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .text-wrapper-8 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 300;
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-13 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-14 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .cloud-upload {
  position: relative;
  width: 32px;
  height: 32px;
}

.DOCUMENT-UPLOAD .frame-15 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 10px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: #6e6e6e;
  font-size: 24px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .or-browse-file-from {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 300;
  color: transparent;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .span {
  color: #484848;
}

.DOCUMENT-UPLOAD .text-wrapper-10 {
  color: #f2b807;
}

.DOCUMENT-UPLOAD .frame-16 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-17 {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: #50992b;
}

.DOCUMENT-UPLOAD .checkmark-circle-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .checkmark-circle {
  position: relative;
  width: 16px;
  height: 16px;
}

.DOCUMENT-UPLOAD .union {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
}

.DOCUMENT-UPLOAD .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #50992b;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-18 {
  display: flex;
  flex-direction: column;
  width: 1031px;
  align-items: flex-start;
  gap: 14px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-19 {
  display: flex;
  width: 1031px;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
  background-color: #f9f9f9;
}

.DOCUMENT-UPLOAD .frame-20 {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .frame-21 {
  display: flex;
  width: 480px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.DOCUMENT-UPLOAD .text-wrapper-12 {
  font-weight: 600;
  color: #484848;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-22 {
  width: 300px;
  align-items: center;
  gap: 10px;
  display: flex;
  padding: 10px;
  position: relative;
}

.DOCUMENT-UPLOAD .frame-23 {
  display: flex;
  width: 233px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.DOCUMENT-UPLOAD .frame-24 {
  display: flex;
  width: 1031px;
  height: 68px;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dadada;
}

.DOCUMENT-UPLOAD .frame-25 {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
}

.DOCUMENT-UPLOAD .text-wrapper-13 {
  font-weight: 400;
  color: #53b175;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-26 {
  width: 300px;
  align-items: center;
  gap: 44px;
  border-radius: 4px;
  border: 1px solid;
  border-color: #dadada;
  display: flex;
  padding: 10px;
  position: relative;
}

.DOCUMENT-UPLOAD .text-wrapper-14 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .vuesax-outline-arrow-2 {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: -1px;
}

.DOCUMENT-UPLOAD .send-circle-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.DOCUMENT-UPLOAD .send-circle {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #ec4f3c;
  border-radius: 15px;
}

.DOCUMENT-UPLOAD .akar-icons-plus {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 8px;
  left: 8px;
}

.DOCUMENT-UPLOAD .frame-27 {
  display: flex;
  flex-direction: column;
  width: 1031px;
  align-items: center;
  gap: 20px;
  padding: 20px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: #dadada;
}

.DOCUMENT-UPLOAD .please-select-the {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: #484848;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.DOCUMENT-UPLOAD .frame-28 {
  display: flex;
  width: 754px;
  height: 46px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: #f1b807;
  border-radius: 4px;
}

.DOCUMENT-UPLOAD .text-wrapper-15 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: #1d1b1b;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.document-upload {
  display: flex;
  flex-direction: column;
  /* width: 565px; */
  height: 364px;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
}

.document-upload .frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 65px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.document-upload .group {
  position: relative;
  width: 179.86px;
  height: 60px;
}

.document-upload .img {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  left: 56px;
}

.document-upload .div {
  position: absolute;
  width: 53px;
  height: 41px;
  top: 4px;
  left: 127px;
}

.document-upload .star {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 27px;
  left: 6px;
}

.document-upload .star-2 {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 6px;
  left: 35px;
}

.document-upload .rectangle {
  width: 9px;
  height: 9px;
  top: 2px;
  left: 2px;
  background-color: #c1efa9;
  position: absolute;
  transform: rotate(45deg);
}

.document-upload .rectangle-2 {
  width: 4px;
  height: 4px;
  top: 11px;
  left: 21px;
  background-color: #50992b;
  position: absolute;
  transform: rotate(45deg);
}

.document-upload .frame-2 {
  position: absolute;
  width: 40px;
  height: 44px;
  top: 4px;
  left: 0;
}

.document-upload .star-3 {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 30px;
  left: 10px;
}

.document-upload .rectangle-3 {
  width: 9px;
  height: 9px;
  top: 9px;
  left: 2px;
  background-color: #77c94e;
  position: absolute;
  transform: rotate(45deg);
}

.document-upload .rectangle-4 {
  width: 4px;
  height: 4px;
  top: 17px;
  left: 22px;
  background-color: #b3ec96;
  position: absolute;
  transform: rotate(45deg);
}

.document-upload .ellipse {
  width: 10px;
  height: 10px;
  top: 0;
  border-radius: 5px;
  border-color: #b3ec96;
  position: absolute;
  left: 30px;
  border: 2px solid;
}

.document-upload .ellipse-2 {
  width: 8px;
  height: 8px;
  top: 27px;
  border-radius: 4px;
  border-color: #77c94e;
  position: absolute;
  left: 30px;
  border: 2px solid;
}

.document-upload .frame-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  position: relative;
  flex: 0 0 auto;
}

.document-upload .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
}

.document-upload .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-400);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.document-upload .our-compliance-team {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-300);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.document-upload .frame-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 31px;
  position: relative;
  flex: 0 0 auto;
}

.document-upload .frame-4 {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationbluesecondarytext-500);
  border-radius: 4px;
}

.document-upload .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationyellowprimary-50);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.addmore {
  border: 1px solid #f1b807 !important;
}
