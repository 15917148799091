.acct-before {
  color: #535151;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: end;

  .signIn {
    color: #535151;
    text-decoration-line: underline;
  }
}

.alt-signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;

  .line {
    width: 112px;
    height: 1px;
    background: #e3e8f1;

    @media screen and (max-width: 540px) {
      width: 30%;
    }
  }
  .text {
    color: #aca9a8;
    font-family: $font-family-sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin: 0px 1.375rem;
  }
}
