//
// Input placeholder color
//

@mixin placeholder($color) {
  // Chrome, Firefox, Opera, Safari 10.1+
  &::placeholder {
    color: $color;
    .required {
      &:after {
        content: '*';
        position: relative;
        font-size: inherit;
        color: $danger;
        padding-left: 0.25rem;
        font-weight: bold;
      }
    }
  }

  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
    .required {
      &:after {
        content: '*';
        position: relative;
        font-size: inherit;
        color: $danger;
        padding-left: 0.25rem;
        font-weight: bold;
      }
    }
  }
}
