//
//header
//

.auth-layout-header {
  // height: 458px !important;
  padding: 0rem 1rem;
  border-bottom: 1px solid #dadada;
  background: #fff;

  @media screen and (min-width: 992px) {
    padding: 0rem 40px;
  }

  .auth-nav {
    height: 5.75rem;
    transition: var(--transition);
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 992px) {
      height: 100px;
    }
  }

  .auth-nav-center {
    width: 100%;
    // max-width: 1440px;
    margin: 0 auto;
    z-index: 1;
  }

  .auth-nav-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo-container {
      display: flex !important;
      gap: 3.125rem !important;
    }
    .auth-nav-logo {
      width: 127.216px;
      height: 20px;
    }
  }

  .hamburger-button {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  .bar {
    width: 30px;
    height: 4px;
    background: $blue-500;
    margin: 6px 0;
    transition: 0.4s;
  }

  .open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .open .bar:nth-child(2) {
    opacity: 0;
  }

  .open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .headerLeftNav {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #061b01;
    justify-content: flex-end;

    .headerUsernameRole {
      display: grid;
      justify-content: center;
      justify-items: center;
      gap: 0.625rem;

      .role {
        padding: 0.125rem 0.375rem;
        min-width: 49px;
        min-height: 22px;
        background: $color-primary;
        border-radius: 2px;

        .role-text {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $white;
        }
      }
    }

    .headerUserAvatar {
      display: flex;
      align-items: center;
      gap: 20px;
      img {
        width: 38px;
      }

      .userImg {
        width: 50px;
        height: 50px;
        border-radius: 50px;

        .dummyHead {
          // display: flex;
          // width: 100%;
          // justify-content: center;
          // align-items: center;

          // border-radius: 100%;
          // height: 50px;
          // border: 1px solid black;

          display: flex;
          align-items: center;
          border-bottom: 1px solid #f0f0f0;

          padding: 0;
          min-width: 250px;

          .avatar-icon {
            background-color: #e0e0e0;
            color: #555;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin-right: 16px;
          }
        }
      }
    }

    @media (max-width: 885px) {
      display: none;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background: $blue-600;
    transition: 0.4s;
    z-index: 100;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  .show-sidebar {
    right: 0;
  }

  .sidebar-menu {
    list-style: none;
    padding: 4rem 0 0 0;
    margin: 0;
    text-align: center;
  }

  .sidebar-menu li {
    padding: 15px;
    text-align: right;
  }

  .sidebar-menu a {
    font-size: 1rem;
    text-transform: capitalize;
    transition: var(--transition);
    color: $white;
    display: inline-block;
    padding: 0.5rem 0;
    text-decoration: none;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 600;
  }
}
