.DevFrame {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  position: relative;
}

.DevFrame .developers-portal {
  position: relative;
  width: 1080px;
  height: 207px;
}

.DevFrame .overlap-group {
  position: relative;
  height: 207px;
  border-radius: 10px;
}

.DevFrame .DevFrame-wrapper {
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 207px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--foundationyellowprimary-50);
  border-radius: 10px;
}

.DevFrame .div {
  display: flex;
  flex-direction: column;
  width: 726px;
  height: 126px;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.DevFrame .div-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .group {
  position: relative;
  width: 202px;
  height: 40px;
}

.DevFrame .div-wrapper {
  position: relative;
  width: 204px;
  height: 40px;
}

.DevFrame .text-wrapper {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.DevFrame .p {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #a3a4a5;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .DevFrame-wrapper-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .div-wrapper-2 {
  display: flex;
  width: 180px;
  height: 48px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 2px 10px;
  position: relative;
  background-color: var(--foundationbluesecondarytext-400);
  border-radius: 4px;
}

.DevFrame .text-wrapper-2 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-50);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.DevFrame .star {
  position: absolute;
  width: 29px;
  height: 30px;
  top: 131px;
  left: 112px;
}

.DevFrame .img {
  position: absolute;
  width: 42px;
  height: 45px;
  top: 26px;
  left: 971px;
}

.DevFrame .div-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .div-4 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .div-wrapper-3 {
  display: flex;
  width: 107px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
}

.DevFrame .text-wrapper-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .div-wrapper-4 {
  display: flex;
  width: 107px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.DevFrame .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-400);
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .div-wrapper-5 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.DevFrame .DevFrame-wrapper-3 {
  display: flex;
  flex-direction: column;
  width: 889px;
  height: 140px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.DevFrame .div-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.DevFrame .div-6 {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .vuesax-outline-eye-wrapper {
  position: relative;
  width: 562px;
  height: 41px;
  border-radius: 2px 0px 0px 2px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.DevFrame .vuesax-outline-eye {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 11px;
  left: 529px;
}

.DevFrame .vuesax-outline-eye-text {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 11px;
  color: #1d1b1b;
  /* left: 529px; */
}

.DevFrame .div-7 {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--foundationyellowprimary-100);
  border-radius: 0px 4px 4px 0px;
}

.DevFrame .vuesax-outline-key {
  position: relative;
  width: 18px;
  height: 18px;
}

.DevFrame .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .div-8 {
  display: flex;
  width: 889px;
  height: 288px;
  align-items: center;
  gap: 56px;
  padding: 20px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
}

.DevFrame .div-9 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  position: relative;
  flex: 0 0 auto;
}

.DevFrame .div-10 {
  position: relative;
  width: 179px;
  height: 179px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.DevFrame .group-2 {
  position: relative;
  width: 100px;
  height: 104px;
  top: 33px;
  left: 40px;
}

.DevFrame .vector {
  position: absolute;
  width: 51px;
  height: 20px;
  top: 0;
  left: 25px;
}

.DevFrame .vector-2 {
  position: absolute;
  width: 17px;
  height: 21px;
  top: 14px;
  left: 3px;
}

.DevFrame .vector-3 {
  position: absolute;
  width: 17px;
  height: 21px;
  top: 14px;
  left: 80px;
}

.DevFrame .img-2 {
  position: absolute;
  width: 100px;
  height: 67px;
  top: 38px;
  left: 0;
  object-fit: cover;
}

.DevFrame .text-wrapper-7 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.DevFrame .imtonigeria {
  position: relative;
  width: 100px;
  height: 103px;
  top: 39px;
  left: 40px;
}

.DevFrame .overlap {
  position: relative;
  height: 103px;
}

.DevFrame .overlap-group-2 {
  position: absolute;
  width: 100px;
  height: 103px;
  top: 0;
  left: 0;
}

.DevFrame .vector-4 {
  position: absolute;
  width: 66px;
  height: 66px;
  top: 37px;
  left: 34px;
}

.DevFrame .vector-5 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 47px;
  left: 57px;
}

.DevFrame .vector-6 {
  position: absolute;
  width: 38px;
  height: 20px;
  top: 72px;
  left: 47px;
}

.DevFrame .vector-7 {
  position: absolute;
  width: 66px;
  height: 66px;
  top: 2px;
  left: 0;
}

.DevFrame .vector-8 {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 11px;
  left: 22px;
}

.DevFrame .vector-9 {
  position: absolute;
  width: 38px;
  height: 20px;
  top: 36px;
  left: 13px;
}

.DevFrame .vector-10 {
  position: absolute;
  width: 23px;
  height: 23px;
  top: 79px;
  left: 33px;
}

.DevFrame .text-wrapper-8 {
  position: absolute;
  top: 82px;
  left: 41px;
  font-family: 'Poppins', Helvetica;
  font-weight: 700;
  color: var(--foundationyellowprimary-100);
  font-size: 11.7px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .vector-11 {
  position: absolute;
  width: 23px;
  height: 23px;
  top: 0;
  left: 49px;
}

.DevFrame .text-wrapper-9 {
  position: absolute;
  top: 3px;
  left: 56px;
  font-family: 'Poppins', Helvetica;
  font-weight: 700;
  color: var(--foundationyellowprimary-100);
  font-size: 11.7px;
  letter-spacing: 0;
  line-height: normal;
}

.DevFrame .vector-12 {
  position: absolute;
  width: 10px;
  height: 1px;
  top: 9px;
  left: 55px;
}

.DevFrame .vector-13 {
  position: absolute;
  width: 10px;
  height: 1px;
  top: 12px;
  left: 55px;
}

.DevFrame .vector-14 {
  position: absolute;
  width: 11px;
  height: 11px;
  top: 72px;
  left: 20px;
}

.DevFrame .vector-15 {
  position: absolute;
  width: 11px;
  height: 11px;
  top: 23px;
  left: 70px;
}

.Transactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.Transactions .div {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 23px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper-2 {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper-3 {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 544px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-4 {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper-4 {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 707px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-wrapper-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: #484848;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .line {
  position: relative;
  width: 1080px;
  height: 1px;
  object-fit: cover;
}

.Transactions .div-6 {
  display: inline-flex;
  align-items: flex-start;
  gap: 22px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .transaction-card {
  display: flex;
  flex-direction: column;
  width: 346px;
  height: 130px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.Transactions .div-7 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-8 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundationbluesecondarytext-500);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 24px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .div-9 {
  display: inline-flex;
  align-items: flex-start;
  gap: 73px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-10 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundation-greeng200);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundationbluesecondarytext-300);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-500);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .Transactions-wrapper-6 {
  display: flex;
  flex-direction: column;
  width: 1082px;
  height: 225px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: var(--card-shadow);
}

.Transactions .div-11 {
  display: flex;
  flex-wrap: wrap;
  width: 1042px;
  align-items: center;
  gap: 10px 10px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationbluesecondarytext-400);
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .div-12 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .Transactions-wrapper-7 {
  display: flex;
  width: 253px;
  align-items: flex-start;
  gap: 17px;
  position: relative;
}

.Transactions .div-13 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 92px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.Transactions .text-wrapper-8 {
  position: relative;
  width: 123px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .img {
  position: relative;
  width: 18px;
  height: 18px;
}

.Transactions .div-14 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .text-wrapper-9 {
  position: relative;
  width: 99px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-400);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .div-15 {
  display: flex;
  width: 253px;
  align-items: center;
  gap: 116px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationbluesecondarytext-50);
}

.Transactions .text-wrapper-10 {
  position: relative;
  width: 99px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-200);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: var(--foundationbluesecondarytext-400);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .div-16 {
  display: inline-flex;
  align-items: flex-end;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .component-wrapper {
  display: inline-flex;
  flex-direction: column;
  height: 41px;
  align-items: flex-start;
  gap: 47px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .component {
  display: flex;
  flex-direction: column;
  width: 115px;
  height: 41px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--foundationyellowprimary-500);
  border-radius: 4px;
}

.Transactions .div-17 {
  display: inline-flex;
  align-items: flex-start;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .next {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .TABLE-transactions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  overflow-x: scroll;
}

.Transactions .div-18 {
  display: flex;
  flex-direction: column;
  width: 1256px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.Transactions .div-19 {
  display: flex;
  width: 1256px;
  align-items: flex-start;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
  background-color: #f9f9f9;
}

.Transactions .div-wrapper-3 {
  display: flex;
  width: 200px;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  position: relative;
}

.Transactions .text-wrapper-12 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationyellowprimary-900);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .Transactions-wrapper-8 {
  position: relative;
  width: 200px;
  height: 41px;
}

.Transactions .div-20 {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.Transactions .text-wrapper-13 {
  text-align: right;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 600;
  color: var(--foundationyellowprimary-900);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .polygon {
  position: relative;
  width: 7.2px;
  height: 6.5px;
}

.Transactions .div-21 {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.Transactions .div-22 {
  width: 1256px;
  height: 60px;
  gap: 2px;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--foundationbluesecondarytext-50);
  display: flex;
  align-items: center;
  position: relative;
}

.Transactions .text-wrapper-14 {
  position: relative;
  width: 150px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: #484848;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-15 {
  position: relative;
  width: 150px;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  color: #484848;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .Transactions-wrapper-9 {
  display: flex;
  width: 72px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
}

.Transactions .div-wrapper-4 {
  display: flex;
  width: 44px;
  height: 18px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 2px;
  position: relative;
  border-radius: 2px;
}

.Transactions .text-wrapper-16 {
  margin-left: -8px;
  margin-right: -8px;
  font-weight: 500;
  color: #484848;
  text-align: right;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .pending-label {
  display: flex;
  width: 100px;
  height: 34px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 2px 10px;
  position: relative;
  background-color: var(--foundation-greeng50);
  border-radius: 4px;
}

.Transactions .not-required {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundation-greeng400);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-17 {
  margin-left: -12.5px;
  margin-right: -12.5px;
  font-weight: 400;
  color: #484848;
  text-align: right;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .text-wrapper-18 {
  margin-left: -13.5px;
  margin-right: -13.5px;
  font-weight: 500;
  color: #484848;
  text-align: right;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Poppins', Helvetica;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .not-required-wrapper {
  display: flex;
  width: 100px;
  height: 34px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 2px 10px;
  position: relative;
  background-color: var(--foundation-orangeo50);
  border-radius: 4px;
}

.Transactions .not-required-2 {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: var(--foundation-orangeo300);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.Transactions .reject-label {
  display: flex;
  width: 100px;
  height: 34px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 2px 10px;
  position: relative;
  background-color: #ffdcdd;
  border-radius: 4px;
}

.Transactions .rejected {
  position: relative;
  width: fit-content;
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
  color: #ec1c24;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.fund-border {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid #ececec;
  align-items: center;

  /* background: var(--Foundation-Yellow-Primary-50, #FEF8E6); */
  /* padding: 1rem; */

  span {
    color: var(--Foundation-Blue-Secondary-Text-500, #29292a);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }

  .active {
    display: flex;
    width: 100px;
    height: 40px;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 4px solid var(--Foundation-Yellow-Primary-500, #f1b807);
    background: var(--Foundation-Yellow-Primary-50, #fef8e6);
    padding: 1rem;
  }
}

.details-sub {
  display: flex;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
  span {
    color: var(--Foundation-Blue-Secondary-Text-400, #545455);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .active {
    display: flex;
    height: 30px;
    padding: 2px 4px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--Foundation-Yellow-Primary-400, #f4c639);
    background: var(--Foundation-Yellow-Primary-50, #fef8e6);
  }
}
