.loginForm {
  padding: 35px 30px;
  font-family: Poppins, sans-serif;
  display: grid;

  h1 {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .forgetPassword {
    text-align: right;
    a {
      color: #757474;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .loginFooter {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    place-self: center;
    gap: 30px;
    width: 259px;
    color: #7c7c7c;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.1%;
    letter-spacing: 0.5px;
    margin-top: 60px;

    .signUp {
      color: #221f1f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;

      a {
        color: #535151;
      }
    }

    span {
      color: #f2b807;
    }
  }

  .forgot-con {
    display: flex;
    justify-content: space-between;
    span {
      color: #7c7c7c;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110.1%; /* 15.414px */
    }
  }
}
