@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
}

.ant-input-group-addon {
    background: #f1b807;
}

.ant-input-wrapper {
    height: 50px !important;
}

.ant-input-lg {
    height: 50px !important;
    /* border: 1px solid #f1b807 !important; */
    outline: none;
    box-shadow: none !important;
}

.admin-bg {
    background: linear-gradient(
            to right,
            #fef8e6 0%,
            #fef8e6 52%,
            #fbe9b2 52%,
            #fbe9b2 100%
    );
}

.admin-bg-hor {
    background: linear-gradient(
            to top,
            #fef8e6 0%,
            #fef8e6 50%,
            #fbe9b2 50%,
            #fbe9b2 100%
    );
}

.verify-developer {
    width: 180px !important;
    height: 48px !important;
    padding: 2px 10px 2px 10px !important;
    border-radius: 4px !important;
    background-color: #545455 !important;
    color: #eaeaea !important;
}

.verify-developer:hover {
    background-color: #545455 !important;
    color: #eaeaea !important;
}

.verify-account-login {
    width: 180px !important;
    height: 48px !important;
    padding: 2px 10px 2px 10px !important;
    border-radius: 4px !important;
    opacity: 0px !important;
    background-color: #f1b807 !important;
    color: #eaeaea !important;
}

.copy-key {
    border-radius: 0px 4px 4px 0px !important;
}

.scrollX {
    overflow: hidden;
}

.scrollX:hover {
    overflow-x: scroll;
}

.scrollX::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border: 1px solid #fff;
    cursor: pointer;
}

.scrollX::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 5px;
}

.scrollX::-webkit-scrollbar-thumb:horizontal {
    background: #eaeaea;
    border-radius: 80px;
    height: 2px;
    cursor: pointer;
}

.scrollX::-webkit-scrollbar-thumb:hover {
    background: #eaeaea;
}

.star::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.fund-view-header {
    display: flex;
    padding: 5px 0px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    color: #484848;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.amt-sty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.ghy-sty {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    align-items: center;
    width: 100%;
}

.bm-card {
    border-radius: 0px 0px 6px 6px;
    border-right: 1px solid var(--Foundation-Blue-Secondary-Text-50, #eaeaea);
    border-bottom: 1px solid var(--Foundation-Blue-Secondary-Text-50, #eaeaea);
    border-left: 1px solid var(--Foundation-Blue-Secondary-Text-50, #eaeaea);
    background: var(--Utils, #f9f9f9);
    display: flex;
    height: 34px;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    span {
        color: var(--Foundation-Blue-Secondary-Text-400, #545455);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.add-threas {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.overlap {
    color: var(--Foundation-Blue-Secondary-Text-400, #545455);
    font-family: Poppins;
    font-size: 4.885px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.doc-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.doc-item {
    flex: 1 1 calc(20% - 10px); /* 5 per row, with some space between */
    margin-bottom: 20px; /* Adjust as needed */
    box-sizing: border-box;
    cursor: pointer;
}

/* Adjust spacing */
.doc-item:not(:nth-child(5n)) {
    margin-right: 15px; /* Space between items */
}


.date_field .ant-picker .ant-picker-outlined {
    display: inherit !important;
}

.list_custom .ant-list-header {
    padding-inline: initial !important;
    padding-block: initial !important;
    border: 1px solid #eaeaea !important;
    border-radius: 0px !important;
}

/*.list_custom .ant-list .ant-list-split .ant-list-bordered {*/
/*    border-radius: initial !important;*/
/*    border: 1px solid #eaeaea !important;*/
/*}*/
